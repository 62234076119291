import { Axios } from "utils";

export const authenticate = async (auth) => {
  const res = await Axios.post("/v2/Accounts/SignIn", auth);
  return res.data;
};

export const getRefreshToken = async (payload) => {
  const res = await Axios.post(`/v2/Accounts/refresh`, payload);
  return res.data;
};

export const IsUserExist = async (emailId) => {
  const res = await Axios.get(`/v2/Accounts/IsUserExists/${emailId}`);
  return res.data;
};

export const supportTicket = async (message) => {
  const res = await Axios.post("/SupportTickets", message);
  return res.data;
};

export const userSignOut = async () => {
  const res = await Axios.post("/v2/Accounts/sign-out");
};

export const getForgotPassword = async (email) => {
  const res = await Axios.get(`/Users/ForgotPassword/${email}`);
  return res.data;
};

export const resetPassword = async (auth) => {
  const res = await Axios.post("/Users/ResetPassword", auth);
  return res.data;
};

export const createPassword = async (auth) => {
  const res = await Axios.post("/v2/Accounts/signup/complete", auth);
  return res.data;
};

export const resendInvitationEmail = async (email) => {
  const res = await Axios.post(
    `/v2/Accounts/signup/start?emailAddress=${email}`
  );
  return res.data;
};
