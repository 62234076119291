import React, { useCallback } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { QuestionModalItem } from "..";
import { useQuestionareContext } from "master";

export const QuestionsModal = () => {
  const questionnaireContext = useQuestionareContext();
  const {
    questionnaire,
    currentQuestionIndex,
    isModalOpen,
    setIsModalOpen,
    onChangeSection,
    setCurrentQuestionIndex,
    getFilteredQuestions,
    markedQuestions,
    getCurrentSectionIndex,
    getCurrentQuestionIndex,
  } = questionnaireContext;

  const currentSectionQuestions = getFilteredQuestions(
    currentQuestionIndex.sectionIndex
  );

  const onCancel = useCallback(() => {
    setIsModalOpen((draft) => {
      draft = {
        type: "",
        isOpen: false,
      };
      return draft;
    });
  }, [isModalOpen]);

  const navigateQuestionHandle = useCallback(
    (questionNumber, sectionId) => {
      const sectionIndex = getCurrentSectionIndex(sectionId);
      const questionIndex = getCurrentQuestionIndex(questionNumber);
      setCurrentQuestionIndex((draft) => {
        draft = {
          sectionIndex,
          questionIndex,
        };
        return draft;
      });
      setIsModalOpen((draft) => {
        draft = {
          type: "",
          isOpen: false,
        };
        return draft;
      });
    },
    [currentQuestionIndex, isModalOpen]
  );

  return (
    <Modal
      isOpen={isModalOpen.type === "allQuestions" && isModalOpen.isOpen}
      toggle={onCancel}
      className="common-modal modal__all-questions"
      scrollable
    >
      <ModalHeader toggle={onCancel}>All Questions</ModalHeader>
      <ModalBody>
        <div className="modal-content-block">
          <div className="modal-questions-wrapper">
            <div className="question-top-header">
              <div className="question-header-btn-group">
                {questionnaire &&
                  questionnaire?.sections?.map((section, index) => (
                    <button
                      key={`question-section-${section?.sectionId}`}
                      onClick={() => onChangeSection(section?.sectionId)}
                      className={`question-header-btn ${
                        currentQuestionIndex?.sectionIndex === index
                          ? "active"
                          : ""
                      }`}
                    >
                      {section?.name}
                    </button>
                  ))}
              </div>
            </div>
            <div className="modal-questions-list-wrap scrollbar-style">
              <ul className="modal-questions-list">
                {currentSectionQuestions &&
                  currentSectionQuestions?.map((question, index) => {
                    return (
                      <QuestionModalItem
                        key={`preview-modal-${question?.questionNumber}-${index}`}
                        question={question ? question : []}
                        index={index}
                        navigateQuestionHandle={navigateQuestionHandle}
                        markedQuestions={markedQuestions}
                      />
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
