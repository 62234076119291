import React, { useEffect } from "react";
import { useCountdown, useQuestionareContext } from "master";

export const AssessmentTimer = () => {
  const questionnaireContext = useQuestionareContext();
  const { onTimeOut, startAssessmentQuery, questionnaire } =
    questionnaireContext;

  const validTill =
    startAssessmentQuery?.data?.validTill || questionnaire?.validTill;

  const currentTime =
    startAssessmentQuery?.data?.currentSystemTime ||
    questionnaire?.currentSystemTime;

  const { timer } = useCountdown({
    validTill,
    currentTime,
  });
  const { hours, minutes, seconds } = timer;

  useEffect(() => {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      onTimeOut();
    }
  }, [hours, minutes, seconds]);

  const formatTimeDigit = (value) => {
    if (value) {
      return value < 10 ? "0" + value : value;
    } else {
      return "00";
    }
  };

  return (
    <h3
      className={`countdown-timer ${
        hours === 0 && minutes <= 1 ? "timeup-warning" : ""
      }`}
    >
      <span>{formatTimeDigit(hours)}</span>:
      <span>{formatTimeDigit(minutes)}</span>:
      <span>{formatTimeDigit(seconds)}</span>
    </h3>
  );
};
