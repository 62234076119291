import React from "react";
import { MainHeader, PageLayout } from "shared";
import { ContactUsPage } from ".";

export const ContactUs = () => {
  return (
    <>
      <MainHeader />
      <PageLayout title="Contact Us" breadcrumb={[{ label: "Contact Us" }]}>
        <ContactUsPage />
      </PageLayout>
    </>
  );
};
