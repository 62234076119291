import React from "react";
import greenCheckedFilled from "assets/images/icons/green-check-filled.svg";
import rightDirectionIcon from "assets/images/icons/right-direction-icon.svg";
import { CommonProgressBar } from "shared";
import { formatDuration } from "utils";

export const CourseCard = ({
  index,
  name,
  totalTasks,
  progress,
  isAssignment,
  isTheory,
  duration = "",
  onClick,
  key,
}) => {
  return (
    <div className="grid-item mini-grid-item" onClick={onClick} key={key}>
      <div className="grid-item-content">
        <div className="grid-left-block col">
          <div className="item-index">{index + 1}</div>
          <div className="text-contents">
            <h2>{name}</h2>
            {!isTheory && (
              <div className="grid-sub-text">
                {totalTasks} Tasks{" "}
                {isAssignment && `• Duration: ${formatDuration(duration)}`}
              </div>
            )}
          </div>
        </div>
        <div className="grid-right-block col-12 col-md-3 justify-content-md-end">
          {!isTheory && (
            <>
              {progress === 100 ? (
                <div className="complete-badge">
                  <img src={greenCheckedFilled} />
                  <span>Completed</span>
                </div>
              ) : (
                <CommonProgressBar width={progress} label={progress + "%"} />
              )}
            </>
          )}
          <div className="right-arrow-element d-none d-md-block">
            <img src={rightDirectionIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
