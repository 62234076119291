import React from "react";
import { LoadingSpinner, PageLayout } from "shared";
import { CourseGroupCard, useLearningMaterials } from "..";
import { useNavigate } from "react-router-dom";

export const CourseGroupList = ({ type = null }) => {
  const navigate = useNavigate();
  const { selectedProgramName, programGroupList, displayName, courseType } =
    useLearningMaterials({
      load: true,
      type,
    });

  const { data } = programGroupList;

  const isAssignment = courseType === 2;
  const isTheory = courseType === 0;
  const path = isAssignment
    ? "assignment"
    : isTheory
    ? "learningmaterials"
    : "practicals";

  const currentName = isAssignment
    ? "Assignment"
    : isTheory
    ? "Learning Materials"
    : "Practicals";

  if (programGroupList.isPending || programGroupList.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout
      title={displayName ? displayName : currentName}
      breadcrumb={[
        { label: selectedProgramName, location: "/program" },
        { label: displayName ? displayName : currentName },
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="page-grid-items page-grid-items--box">
            {data &&
              data.map((element) => {
                return (
                  <CourseGroupCard
                    name={element?.name}
                    numberOfCourses={element?.numberOfCourses}
                    progress={element.taskProgressPercentage}
                    onClick={() => {
                      sessionStorage.setItem(
                        "courseGroup",
                        JSON.stringify(element)
                      );
                      navigate(`/program/${path}/courseslist`);
                    }}
                    isAssignment={isAssignment}
                    isTheory={isTheory}
                    duration={element?.totalDuration}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
