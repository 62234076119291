import React, { useCallback, useEffect } from "react";
import { LoadingSpinner, PageLayout, ErrorComponent } from "shared";
import {
  AssessmentItem,
  useAssessment,
  AssessmentScheduleModal,
  ScheduleConfirmModal,
  ScheduleSuccessfullModal,
  ScheduleUnsuccessfullModal,
} from "..";
import { getSelectedProgram, clearLocalKeysWithPrefix } from "utils";
import { useQuestionareContext } from "master";
import noAssessmentImage from "assets/images/assessmet-empty-graphics.svg";
import { useNavigate } from "react-router-dom";

export const AssessmentList = () => {
  const navigate = useNavigate();
  const { onToggleInstruction, setMarkedQuestions } = useQuestionareContext();

  const {
    assessmentsQuery,
    assessmentScheduleQuery,
    assessmentSlotQuery,
    setScheduleId,
    scheduleId,
    selectedSlotId,
    setSelectedSlotId,
    createSchedule,
    isSlotInfoOpen,
    setIsSlotInfoOpen,
    onAssessmentScheduleModal,
  } = useAssessment({
    load: true,
  });

  const {
    isPending,
    data: assessmentListData,
    isRefetching,
    isFetching,
  } = assessmentsQuery;

  const selectedProgram = getSelectedProgram();

  const { data: schedules } = assessmentScheduleQuery;
  const { data: slots, isLoading: isSlotLoading } = assessmentSlotQuery;

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("assessmentToken");
    sessionStorage.removeItem("slotId");
    sessionStorage.removeItem("attemptId");
    sessionStorage.removeItem("assessmentId");
  }, []);

  useEffect(() => {
    return () => {
      if (isSlotInfoOpen.isOpen) {
        window.history.forward();
      }
    };
  }, [isSlotInfoOpen.isOpen]);

  const onCreateAssessmentSchedule = useCallback(() => {
    setIsSlotInfoOpen((draft) => {
      draft.type = "confirm";
      draft.isOpen = true;
      return draft;
    });
  }, [isSlotInfoOpen]);

  const onStartAssessment = ({
    assessmentId,
    slotId,
    attemptId,
    noOfQuestions,
    maxTime,
    maxAttempt,
    instructions,
    name,
    attemptStatus,
    isStartAssessment = false,
  }) => {
    const isStart = attemptStatus === 2;
    const isScheduled = attemptStatus === 1;

    if (isStart || isScheduled) {
      setMarkedQuestions((draft) => {
        draft = [];
        return draft;
      });
    }

    if (isStartAssessment) {
      clearLocalKeysWithPrefix("assessmentData_", "sessionStorage");
      clearLocalKeysWithPrefix("currentQuestionIndex_", "sessionStorage");
    }
    clearLocalKeysWithPrefix("assessmentMasterId_", "sessionStorage");
    sessionStorage.setItem("assessmentId", assessmentId);
    sessionStorage.setItem("slotId", slotId);
    sessionStorage.setItem("attemptId", attemptId);

    const instructionsPayload = {
      noOfQuestions,
      maxTime,
      maxAttempt,
      instructions,
      name,
    };
    onToggleInstruction(instructionsPayload, "assessmentList");
  };

  const slotInfoModalClose = useCallback(() => {
    setIsSlotInfoOpen((draft) => {
      draft.isOpen = false;
      draft.type = "";
      return draft;
    });
  }, [isSlotInfoOpen]);

  const onConfirm = () => {
    createSchedule.mutate({ slotId: selectedSlotId });
    slotInfoModalClose();
  };

  const breadcrumb = [
    { label: selectedProgram?.name, location: "/program" },
    { label: "Assessment" },
  ];

  if (isPending) return <LoadingSpinner />;

  return (
    <>
      <PageLayout
        title={assessmentListData?.length > 0 ? "Assessment" : false}
        breadcrumb={breadcrumb}
      >
        <div className="col-12">
          <div className="assessment-page-wrapper">
            <ul className="assessments-main-list">
              {assessmentListData && assessmentListData?.length > 0 ? (
                assessmentListData?.map((item, index) => {
                  return (
                    <AssessmentItem
                      key={`assessment-${item.assessmentId}-${index}`}
                      assessmentData={item}
                      onScheduleAssessment={onAssessmentScheduleModal}
                      onStartAssessment={onStartAssessment}
                      isFetching={
                        isFetching || isRefetching || createSchedule?.isPending
                      }
                    />
                  );
                })
              ) : (
                <ErrorComponent
                  title="No Assessment Found!"
                  message="There is no assessment assigned for you at the moment."
                  image={noAssessmentImage}
                  btnLabel={"Go back"}
                  onClick={() => navigate("/program", { replace: true })}
                />
              )}
            </ul>
          </div>
        </div>

        {/* SCHEDULE MODAL */}

        {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "scheduleModal" && (
          <AssessmentScheduleModal
            isOpen={
              isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "scheduleModal"
            }
            setScheduleId={setScheduleId}
            scheduleId={scheduleId}
            selectedSlotId={selectedSlotId}
            setSelectedSlotId={setSelectedSlotId}
            scheduleDateList={schedules}
            slots={slots}
            isSlotLoading={isSlotLoading}
            onCreateAssessmentSchedule={onCreateAssessmentSchedule}
            onCancel={() => {
              setIsSlotInfoOpen((draft) => {
                draft.isOpen = false;
                draft.type = "";
                return draft;
              });
            }}
            setIsSlotInfoOpen={setIsSlotInfoOpen}
            isSlotInfoOpen={isSlotInfoOpen}
          />
        )}

        {/* SCHEDULE CONFIRM MODAL */}

        {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "confirm" && (
          <ScheduleConfirmModal
            isOpen={isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "confirm"}
            onCancel={slotInfoModalClose}
            data={isSlotInfoOpen?.data}
            onConfirm={onConfirm}
          />
        )}

        {/* SCHEDULE SUCCESS MODAL */}

        {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "success" && (
          <ScheduleSuccessfullModal
            isOpen={isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "success"}
            onCancel={slotInfoModalClose}
            data={isSlotInfoOpen?.data}
            onConfirm={slotInfoModalClose}
          />
        )}

        {/* SCHEDULE UNSUCCESS MODAL */}

        {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "unsuccess" && (
          <ScheduleUnsuccessfullModal
            isOpen={
              isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "unsuccess"
            }
            onCancel={slotInfoModalClose}
            data={isSlotInfoOpen?.data}
            onConfirm={slotInfoModalClose}
          />
        )}
      </PageLayout>
    </>
  );
};
