import React from "react";
import { MainHeader, PageLayout } from "shared";
import { RefundPolicyPage } from ".";

export const RefundPolicy = () => {
  return (
    <>
      <MainHeader />
      <PageLayout
        title="Refund Policy"
        breadcrumb={[{ label: "Refund Policy" }]}
      >
        <RefundPolicyPage />
      </PageLayout>
    </>
  );
};
