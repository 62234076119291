import React from "react";
import parse from "html-react-parser";
import { useRefundPolicy } from "..";
import { LoadingSpinner } from "shared";

export const RefundPolicyPage = () => {
  const { settingsInfo } = useRefundPolicy({
    load: true,
    key: "page_Refund_Policy",
  });
  const { data, isLoading } = settingsInfo;

  if (isLoading) return <LoadingSpinner />;

  return <p>{data ? parse(data?.content) : ""}</p>;
};
