import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  useStartAssessmentQuery,
  deleteAnswer,
  answerAssessment,
  submitAnswers,
  generateAssessmentToken,
  useAppScope,
} from "..";

import { useImmer } from "use-immer";
import { errorFormatter, useNetworkStatus, useMediaPreview } from "shared";

const initInstruction = {
  noOfQuestions: "",
  maxTime: "",
  maxAttempt: "",
  instructions: "",
  name: "",
};

export const useQuestionnaire = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAppState } = useAppScope();
  const [searchParams] = useSearchParams();
  const { isOnline } = useNetworkStatus();
  const mediaPreviewState = useMediaPreview();
  const hasSubmitted = useRef(false);
  const [questionnaire, setQuestionnaire] = useImmer(null);

  const assessmentId_q = searchParams.get("assessmentId");
  const slotId_q = searchParams.get("slotId");
  const attemptId_q = searchParams.get("attemptId");
  const assessmentToken_q = searchParams.get("assessmentToken");

  const [assessmentId, setAssessmentId] = useImmer(null);
  const [slotId, setSlotId] = useImmer(null);
  const [attemptId, setAtemptId] = useImmer(null);
  const [assessmentToken, setAssessmentToken] = useImmer(null);
  const [isQuestionnaireEnabled, setIsQuestionnaireEnabled] = useImmer(false);

  const isFromMobileApp = sessionStorage.getItem("isFromMobileApp");
  const isAssessmentStartedFromMobileApp =
    assessmentId_q && slotId_q && attemptId_q && assessmentToken_q;

  useEffect(() => {
    if (assessmentId_q && slotId_q && attemptId_q && assessmentToken_q) {
      setAssessmentId(assessmentId_q);
      setSlotId(slotId_q);
      setAtemptId(attemptId_q);
      setAssessmentToken(assessmentToken_q);

      sessionStorage.setItem("assessmentId", assessmentId_q);
      sessionStorage.setItem("slotId", slotId_q);
      sessionStorage.setItem("attemptId", attemptId_q);
      sessionStorage.setItem("assessmentToken", assessmentToken_q);
    } else {
      setAssessmentId(JSON.parse(sessionStorage.getItem("assessmentId")));
      setSlotId(JSON.parse(sessionStorage.getItem("slotId")));
      setAtemptId(JSON.parse(sessionStorage.getItem("attemptId")));
      setAssessmentToken(sessionStorage.getItem("assessmentToken"));
    }
  }, []);

  useEffect(() => {
    if (isAssessmentStartedFromMobileApp || !!isFromMobileApp) {
      setAppState((draft) => {
        draft.registrationId = questionnaire?.registrationId;
        draft.user = {
          firstName: questionnaire?.studentName || "",
        };
        return draft;
      });
    } else {
      setAppState((draft) => {
        draft.registrationId = questionnaire?.registrationId;
        return draft;
      });
    }
  }, [questionnaire]);

  useEffect(() => {
    const enabled =
      isOnline &&
      assessmentId != undefined &&
      assessmentToken != null &&
      slotId != undefined &&
      (location.pathname === "/program/assessment/start" ||
        location.pathname === "/program/assessment/review");

    if (enabled) {
      setIsQuestionnaireEnabled(true);
    }
  }, [isOnline, assessmentId, slotId, assessmentToken]);

  const assessmentLocalKey = `assessmentData_${assessmentId}`;

  const [allAnsweredQuestions, setAllAnsweredQuestions] = useImmer([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useImmer({
    sectionIndex: -1,
    questionIndex: -1,
  });
  const [isModalOpen, setIsModalOpen] = useImmer({
    type: "",
    isOpen: false,
    actionFrom: "",
  });
  const [currentQuestion, setCurrentQuestion] = useImmer(null);
  const [markedQuestions, setMarkedQuestions] = useImmer([]);
  const [instruction, setInstruction] = useImmer(initInstruction);
  const [help, setHelp] = useImmer("");
  const [isTimeout, setIsTimeout] = useImmer(false);
  const [answerPayload, setAnswerPayload] = useImmer(null);
  const [clearPayload, setClearPayload] = useImmer({
    answerId: null,
    questionId: null,
    sectionId: null,
  });

  const startAssessmentQuery = useStartAssessmentQuery({
    enabled: isQuestionnaireEnabled,
    assessmentId,
    slotId,
    attemptId,
    assessmentTokenState: assessmentToken,
  });

  const errorDetails =
    startAssessmentQuery && startAssessmentQuery?.error?.response;

  useEffect(() => {
    if (startAssessmentQuery?.error) {
      if (errorDetails?.status === 400) {
        navigate("/program/assessment/expired", {
          state: {
            message: errorDetails?.data?.message,
            status: errorDetails?.status,
          },
          replace: true,
        });
      } else if (errorDetails?.status === 405) {
        setIsModalOpen((draft) => {
          draft = {
            type: "tokenExpired",
            isOpen: true,
            message: errorDetails?.data?.message,
          };
          return draft;
        });
      }
    }
  }, [startAssessmentQuery?.error]);

  useEffect(() => {
    if (startAssessmentQuery && startAssessmentQuery?.data) {
      const getLocalAssessmentData = sessionStorage.getItem(assessmentLocalKey)
        ? JSON.parse(sessionStorage.getItem(assessmentLocalKey))
        : null;

      let formattedQuestions = [];
      if (!getLocalAssessmentData) {
        formattedQuestions = startAssessmentQuery?.data?.questions?.map(
          (q, i) => {
            return {
              ...q,
              questionNumber: i + 1,
            };
          }
        );
      }

      const qst =
        getLocalAssessmentData === null
          ? {
              ...startAssessmentQuery?.data,
              questions: formattedQuestions,
            }
          : {
              ...getLocalAssessmentData,
            };

      setQuestionnaire((draft) => {
        draft = qst;
        return draft;
      });

      const index = sessionStorage.getItem(
        `currentQuestionIndex_${assessmentId}`
      )
        ? JSON.parse(
            sessionStorage.getItem(`currentQuestionIndex_${assessmentId}`)
          )
        : {
            sectionIndex: 0,
            questionIndex: 0,
          };
      setCurrentQuestionIndex((draft) => {
        draft = index;
        return draft;
      });
    }
  }, [startAssessmentQuery.data]);

  useEffect(() => {
    if (questionnaire !== null) {
      sessionStorage.setItem(assessmentLocalKey, JSON.stringify(questionnaire));

      setCurrentQuestion((draft) => {
        draft = questionnaire?.questions[currentQuestionIndex.questionIndex];
        return draft;
      });
    }
  }, [questionnaire]);

  useEffect(() => {
    if (!isOnline) {
      const index = sessionStorage.getItem(
        `currentQuestionIndex_${assessmentId}`
      )
        ? JSON.parse(
            sessionStorage.getItem(`currentQuestionIndex_${assessmentId}`)
          )
        : {
            sectionIndex: 0,
            questionIndex: 0,
          };

      setCurrentQuestionIndex((draft) => {
        draft = index;
        return draft;
      });

      const getLocalAssessmentData = sessionStorage.getItem(assessmentLocalKey)
        ? JSON.parse(sessionStorage.getItem(assessmentLocalKey))
        : null;

      if (getLocalAssessmentData) {
        setQuestionnaire((draft) => {
          draft = getLocalAssessmentData;
          return draft;
        });
      }
    }
  }, []);

  useEffect(() => {
    if (questionnaire !== null) {
      sessionStorage.setItem(
        `currentQuestionIndex_${assessmentId}`,
        JSON.stringify(currentQuestionIndex)
      );
      setCurrentQuestion((draft) => {
        draft = questionnaire?.questions[currentQuestionIndex.questionIndex];
        return draft;
      });
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const sectionIndex = getCurrentSectionIndex(currentQuestion?.sectionId);
    setCurrentQuestionIndex((draft) => {
      draft.sectionIndex = sectionIndex;
      return draft;
    });
  }, [currentQuestion]);

  useEffect(() => {
    sessionStorage.setItem("markedQuestions", JSON.stringify(markedQuestions));
  }, [markedQuestions]);

  const localMarkedQuestions = sessionStorage.getItem("markedQuestions")
    ? JSON.parse(sessionStorage.getItem("markedQuestions"))
    : [];

  useEffect(() => {
    setMarkedQuestions((draft) => {
      draft = localMarkedQuestions;
      return draft;
    });
    setAnswerPayload((draft) => {
      draft = null;
      return draft;
    });
  }, []);

  useEffect(() => {
    const filteredAnswer =
      questionnaire &&
      questionnaire?.questions.filter((q) => {
        return q.answer !== null;
      });

    const answers = filteredAnswer
      ? filteredAnswer.map((q) => {
          const {
            id = 0,
            assessmentMasterId,
            questionId,
            choiceId,
            sectionId,
          } = q.answer;
          return {
            id,
            assessmentMasterId,
            questionId,
            choiceId,
            sectionId,
          };
        })
      : [];

    setAllAnsweredQuestions((draft) => {
      draft = [...answers];
      return draft;
    });
  }, [questionnaire]);

  const answeringQuestion = useMutation({
    mutationFn: answerAssessment,
    onError: (e) => {
      if (e && e?.response?.status === 405) {
        setIsModalOpen((draft) => {
          draft = {
            type: "tokenExpired",
            isOpen: true,
            message: e?.response?.data?.message,
          };
          return draft;
        });
      } else {
        errorFormatter(e);
      }
    },
    onSuccess: (e) => {
      if (e) {
        setQuestionnaire((draft) => {
          const question = draft?.questions?.find((q) => {
            return q.questionId === e.questionId && q.sectionId === e.sectionId;
          });
          question.answer = e;
          return draft;
        });
      }
    },
    onSettled: () => {
      setAnswerPayload((draft) => {
        draft = null;
        return draft;
      });
    },
  });

  useEffect(() => {
    if (answerPayload) {
      setQuestionnaire((draft) => {
        let question = draft?.questions?.find((q) => {
          return (
            q?.questionId === answerPayload?.questionId &&
            q?.sectionId === answerPayload?.sectionId
          );
        });
        question.answer = answerPayload;
      });

      if (isOnline) {
        answeringQuestion.mutate(answerPayload);
      }
    }
  }, [answerPayload]);

  useEffect(() => {
    if (clearPayload) {
      const { answerId, questionId, sectionId } = clearPayload;

      if (isOnline && answerId) {
        clearAnswer.mutate(answerId);
      } else {
        if (questionId && sectionId) {
          setQuestionnaire((draft) => {
            let question = draft?.questions?.find((q) => {
              return q?.questionId === questionId && q?.sectionId === sectionId;
            });
            question.answer = null;
          });
        }
      }
    }
  }, [clearPayload]);

  const createAssessmentToken = useMutation({
    mutationFn: generateAssessmentToken,
    onError: (e) => {
      errorFormatter(e);
    },
    onSuccess: (assessmentToken) => {
      if (assessmentToken) {
        sessionStorage.setItem("assessmentToken", assessmentToken);

        const newWindow = window.open("", "_blank");
        if (!newWindow) {
          alert("Pop-up blocked. Please allow pop-ups for this site.");
          return;
        }
        newWindow.location.href = "/program/assessment/start";
      }
    },
  });

  const clearAnswer = useMutation({
    mutationFn: deleteAnswer,
    onError: (e) => {
      if (e && e?.response?.status === 405) {
        setIsModalOpen((draft) => {
          draft = {
            type: "tokenExpired",
            isOpen: true,
            message: e?.response?.data?.message,
          };
          return draft;
        });
      } else {
        errorFormatter(e);
      }
    },
    onSuccess: (e) => {
      if (e) {
        setQuestionnaire((draft) => {
          const question = draft?.questions?.find((q) => {
            return (
              q?.questionId === e?.questionId && q?.sectionId === e?.sectionId
            );
          });
          question.answer = null;
          return draft;
        });
        setClearPayload((draft) => {
          draft = {
            answerId: null,
            questionId: null,
            sectionId: null,
          };
          return draft;
        });
      }
    },
    onSettled: (e) => {
      if (e) {
        setQuestionnaire((draft) => {
          const question = draft?.questions?.find((q) => {
            return (
              q?.questionId === e?.questionId && q?.sectionId === e?.sectionId
            );
          });
          question.answer = null;
          return draft;
        });
        setClearPayload((draft) => {
          draft = {
            answerId: null,
            questionId: null,
            sectionId: null,
          };
          return draft;
        });
      }
    },
  });

  const submitAllAnswers = useMutation({
    mutationFn: submitAnswers,
    onError: (e) => {
      if (e && e?.response?.status === 405) {
        setIsModalOpen((draft) => {
          draft = {
            type: "tokenExpired",
            isOpen: true,
            message: e?.response?.data?.message,
          };
          return draft;
        });
      } else {
        errorFormatter(e);
      }
    },
    onSuccess: (e) => {
      if (e) {
        setIsModalOpen((draft) => {
          draft.type = "";
          draft.isOpen = false;
          return draft;
        });

        navigate("/program/assessment/result", {
          replace: true,
        });
      }
    },
  });

  const getCurrentQuestionIndex = (questionNumber) => {
    const currentQuestonIndex = questionnaire?.questions.findIndex((q) => {
      return q.questionNumber === questionNumber;
    });
    return currentQuestonIndex;
  };

  const getCurrentSectionIndex = (sectionId) => {
    const currentSectionIndex = questionnaire?.sections.findIndex((s) => {
      return s.sectionId === sectionId;
    });
    return currentSectionIndex;
  };

  const onChangeSection = useCallback(
    (sectionId) => {
      const sectionIndex = getCurrentSectionIndex(sectionId);
      const filteredQuestions = getFilteredQuestions(sectionIndex);
      const firstQuestionIndex = questionnaire?.questions.indexOf(
        filteredQuestions[0]
      );
      setCurrentQuestionIndex((draft) => {
        draft = {
          sectionIndex,
          questionIndex: firstQuestionIndex,
        };
        return draft;
      });
    },
    [currentQuestionIndex]
  );

  const onChangeSidebarButton = useCallback(
    (sectionId, questionIndex) => {
      const sectionIndex = getCurrentSectionIndex(sectionId);
      setCurrentQuestionIndex((draft) => {
        draft = {
          sectionIndex,
          questionIndex,
        };
        return draft;
      });
    },
    [currentQuestionIndex]
  );

  const onAnswer = ({ questionId, choiceId, sectionId }) => {
    if (questionId && choiceId) {
      const { assessmentMasterId, attemptId } = questionnaire && questionnaire;

      setAnswerPayload((draft) => {
        draft = {
          assessmentMasterId,
          questionId,
          choiceId,
          attemptId,
          sectionId,
        };
        return draft;
      });
    }
  };

  const onClearAnswer = (
    answerId = null,
    questionId = null,
    sectionId = null
  ) => {
    setClearPayload((draft) => {
      draft = {
        answerId,
        questionId,
        sectionId,
      };
      return draft;
    });
  };

  const submitAnswersList = () => {
    const getLocalAssessmentData = sessionStorage.getItem(assessmentLocalKey)
      ? JSON.parse(sessionStorage.getItem(assessmentLocalKey))
      : null;

    sessionStorage.setItem(
      `assessmentMasterId_${assessmentId}`,
      getLocalAssessmentData?.assessmentMasterId
    );

    submitAllAnswers.mutate({
      assessmentMasterId: getLocalAssessmentData?.assessmentMasterId,
      payload: allAnsweredQuestions,
    });
  };

  const onSubmitAnswer = () => {
    if (isOnline) {
      submitAnswersList();
    } else {
      alert("You are offline now!. Please check your internet connection.");
    }
  };

  const getFilteredQuestions = (sectionIndex) => {
    let filteredQuestions = [];
    if (questionnaire) {
      const currentSection = questionnaire?.sections[sectionIndex];
      filteredQuestions = questionnaire?.questions.filter(
        (q) => q.sectionId === currentSection?.sectionId
      );
    }
    return filteredQuestions;
  };

  const onNext = useCallback(() => {
    setCurrentQuestionIndex((draft) => {
      draft.questionIndex = draft.questionIndex + 1;
      return draft;
    });
  }, [currentQuestionIndex]);

  const onPrevious = useCallback(() => {
    setCurrentQuestionIndex((draft) => {
      draft.questionIndex = draft.questionIndex - 1;
      return draft;
    });
  }, [currentQuestionIndex]);

  const onMarkQuestion = useCallback(
    (question) => {
      const existingItem = markedQuestions.find(
        (q) =>
          q?.questionId === question?.questionId &&
          q?.sectionId === question?.sectionId
      );
      if (existingItem) {
        const newArray = markedQuestions.filter((q) => {
          return !(
            q?.questionId === existingItem?.questionId &&
            q?.sectionId === existingItem?.sectionId
          );
        });
        setMarkedQuestions((draft) => {
          draft = [...newArray];
          return draft;
        });
      } else {
        setMarkedQuestions((draft) => {
          draft = [...draft, question];
          return draft;
        });
      }
    },
    [markedQuestions]
  );

  const onTimeOut = () => {
    setIsModalOpen((draft) => {
      draft = { type: "timeOut", isOpen: true };
      return draft;
    });
    setIsTimeout((draft) => {
      draft = true;
      return draft;
    });

    navigate("/program/assessment/review", { replace: true });
    setTimeout(() => {
      if (!hasSubmitted.current) {
        hasSubmitted.current = true;
        onSubmitAnswer();
      }
    }, 5000);
  };

  const onFindQuestion = useCallback(
    (sectionId, questionId) => {
      if (questionnaire) {
        const questionIndex = questionnaire?.questions.findIndex((question) => {
          return (
            question?.questionId === questionId &&
            question?.sectionId === sectionId
          );
        });
        const sectionIndex = questionnaire?.sections.findIndex((question) => {
          return question?.sectionId === sectionId;
        });
        setCurrentQuestionIndex((draft) => {
          draft = {
            sectionIndex,
            questionIndex,
          };
          return draft;
        });
      }
    },
    [currentQuestionIndex]
  );

  const onToggleInstruction = (instruction = null, from = "") => {
    if (isModalOpen.type === "" && isModalOpen.isOpen === false) {
      if (instruction) {
        setInstruction((draft) => {
          draft = instruction;
          return draft;
        });
      } else {
        const { maxAttempt, noOfQuestions, maxTime, instructions } =
          startAssessmentQuery?.data;

        setInstruction((draft) => {
          draft = {
            maxAttempt,
            noOfQuestions,
            maxTime,
            instructions,
          };
          return draft;
        });
      }

      setIsModalOpen((draft) => {
        draft = {
          type: "instruction",
          isOpen: true,
          from,
        };
        return draft;
      });
    } else {
      setInstruction((draft) => {
        draft = initInstruction;
        return draft;
      });
      setIsModalOpen((draft) => {
        draft = {
          type: "",
          isOpen: false,
          from: "",
        };
        return draft;
      });
    }
  };

  const onToggleHelp = () => {
    if (isModalOpen.type === "" && isModalOpen.isOpen === false) {
      setIsModalOpen((draft) => {
        draft = {
          type: "help",
          isOpen: true,
        };
        return draft;
      });
      setHelp((draft) => {
        draft = startAssessmentQuery?.data?.help;
        return draft;
      });
    } else {
      setHelp((draft) => {
        draft = "";
        return draft;
      });
      setIsModalOpen((draft) => {
        draft = {
          type: "",
          isOpen: false,
        };
        return draft;
      });
    }
  };

  const disableBackButton = () => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", function (e) {
      window.history.pushState(null, null, window.location.href);
    });
  };

  return {
    startAssessmentQuery,
    questionnaire,
    markedQuestions,
    currentQuestionIndex,
    isOnline,
    setCurrentQuestionIndex,
    currentQuestion,
    onChangeSection,
    isModalOpen,
    setIsModalOpen,
    getFilteredQuestions,
    onFindQuestion,
    onAnswer,
    onClearAnswer,
    onNext,
    onPrevious,
    onMarkQuestion,
    onTimeOut,
    instruction,
    setInstruction,
    onToggleInstruction,
    onToggleHelp,
    submitAllAnswers,
    help,
    onChangeSidebarButton,
    getCurrentSectionIndex,
    getCurrentQuestionIndex,
    isTimeout,
    setMarkedQuestions,
    disableBackButton,
    onSubmitAnswer,
    mediaPreviewState,
    answeringQuestion,
    clearAnswer,
    createAssessmentToken,
    answerPayload,
    clearPayload,
  };
};
