import React from "react";
import { Link } from "react-router-dom";
import { FooterLink } from "shared/components";
import appInfo from "../../../package.json";

export const AuthLayout = ({ leftDivImage, children }) => {
  const brandLogo = process.env.REACT_APP_MAIN_LOGO;

  return (
    <>
      <header className="auth-header">
        <div className="container">
          <div className="row">
            <div className="header-block d-flex align-items-center justify-content-between">
              <Link className="brand-logo" to="/login">
                <img src={brandLogo} alt="nergy vidya" />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <section className="auth-wrapper">
        <div className="container-fluid">
          <div className="row window-height-md-100 window-height-mobile">
            <div className="col-12 col-sm-6 col-lg-7  auth-graphics-block d-flex justify-content-center align-items-center pt-4 pb-4 pt-sm-0 pb-sm-0">
              <figure className="graphic-block">
                <img src={leftDivImage} alt="" />
              </figure>
              <div className="figure-bg"></div>
            </div>
            <div className="col-12 col-sm-6 col-lg-5 d-flex d-sm-block flex-column justify-content-between">
              <div className="auth-form window-height-md-100 d-sm-flex justify-content-sm-start align-items-sm-center pt-4 pt-sm-0">
                <div className="form-scroll">
                  <div className="form-block">{children}</div>
                </div>
              </div>
              <div className="auth-form-footer">
                <div>
                  <nav className="footer-nav">
                    <ul className="d-flex align-items-center justify-content-center">
                      <FooterLink to={"/privacy"} label={"Privacy"} />
                      <FooterLink to={"/terms"} label={"Terms"} />
                      <FooterLink to={"/faq"} label={"FAQ"} />
                      <FooterLink
                        to={"/refund-policy"}
                        label={"Refund Policy"}
                      />
                      <FooterLink to={"/contact-us"} label={"Contact Us"} />
                    </ul>
                  </nav>
                </div>
                <div className="mt-1 mb-1 d-flex align-items-center justify-content-between">
                  <span className="app-version">{`v${appInfo.version}`}</span>
                  <span className="copyright-text">©NERGY INDIA PVT LTD</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
