import React, { forwardRef } from "react";
import { courseTaskTypeFinder, trimText } from "utils";

export const CourseTaskSideBarCard = forwardRef((props, ref) => {
  const {
    sino,
    title,
    taskGroup,
    isComplete,
    isCurrent,
    onClick,
    courseType,
    duration,
  } = props;
  return (
    <li
      ref={ref}
      className={`sidebar-item 
      ${isComplete ? "completed" : ""} 
      ${isCurrent ? "current" : ""}`}
      onClick={onClick}
    >
      <a>
        <div className="index-span">{sino}</div>
        <div className="item-content">
          <div className="md-text">{trimText(title)}</div>
          <span className="sm-text">
            {courseTaskTypeFinder(taskGroup, false)}{" "}
            {courseType === 2 && `• Duration:${duration}`}
          </span>
        </div>
      </a>
    </li>
  );
});
