import React from "react";
import greenCheckedFilled from "assets/images/icons/green-check-filled.svg";
import { CommonProgressBar } from "shared";
import { formatDuration } from "utils";

export const CourseGroupCard = ({
  name,
  numberOfCourses,
  progress,
  onClick,
  isAssignment,
  isTheory,
  duration = "",
}) => {
  return (
    <div className="grid-item mini-grid-item" onClick={onClick}>
      <div className="grid-item-content">
        <h2>{name}</h2>
        {!isTheory && (
          <div className="grid-sub-text">{numberOfCourses} Courses</div>
        )}
        {!isTheory && (
          <>
            {progress === 100 ? (
              <div className="complete-badge">
                <img src={greenCheckedFilled} />
                <span>Completed</span>
              </div>
            ) : (
              <CommonProgressBar width={progress} label={progress + "%"} />
            )}
          </>
        )}
        {isAssignment && (
          <span>Total Duration: {formatDuration(duration)} </span>
        )}
      </div>
    </div>
  );
};
