import React, { useEffect } from "react";
import { ReviewItem, AnswerSubmitConfirmModal } from "..";
import { useNavigate } from "react-router-dom";
import { useQuestionareContext } from "master";
import { PageLayout } from "shared";

export const Review = () => {
  const navigate = useNavigate();
  const questionnaireContext = useQuestionareContext();
  const {
    questionnaire,
    markedQuestions,
    onFindQuestion,
    isModalOpen,
    setIsModalOpen,
    isTimeout,
    disableBackButton,
    onSubmitAnswer,
    submitAllAnswers,
  } = questionnaireContext;

  const isSubmittingAnswers = submitAllAnswers?.isLoading;

  const onQuestionClick = (sectionId, questionId) => {
    onFindQuestion(sectionId, questionId);
    navigate("/program/assessment/start");
  };

  useEffect(() => {
    if (isTimeout) {
      disableBackButton();
    }
  }, [isTimeout]);

  const goBackToQuestions = () => {
    if (!isTimeout) {
      navigate("/program/assessment/start", { replace: true });
    }
  };

  return (
    <PageLayout isPageHeaderRequired={false}>
      <div className="review-page-header d-flex justify-content-between align-items-center">
        <h3 className="mb-0">Review Your Answer</h3>
        <div className="review-header-actions">
          <button
            onClick={goBackToQuestions}
            className="btn btn-blue-outline"
            disabled={isTimeout}
          >
            Back
          </button>
          <button
            onClick={() =>
              setIsModalOpen((draft) => {
                draft.type = "submitConfirm";
                draft.isOpen = true;
                return draft;
              })
            }
            className="btn btn-blue"
          >
            Submit
          </button>
        </div>
      </div>
      <div className="review-items-list">
        <ul className="review-item-ul">
          {!!questionnaire &&
            questionnaire?.questions?.map((question, index) => {
              return (
                <ReviewItem
                  key={question.questionId}
                  question={question}
                  index={index}
                  markedQuestions={markedQuestions}
                  onQuestionClick={onQuestionClick}
                  isTimeout={isTimeout}
                />
              );
            })}
        </ul>
      </div>

      {isModalOpen.isOpen && isModalOpen.type === "submitConfirm" && (
        <AnswerSubmitConfirmModal
          isOpen={isModalOpen.isOpen && isModalOpen.type === "submitConfirm"}
          onCancel={() => {
            if (!isSubmittingAnswers) {
              setIsModalOpen((draft) => {
                draft.type = "";
                draft.isOpen = false;
                return draft;
              });
            }
          }}
          onSubmit={onSubmitAnswer}
          isSubmittingAnswers={isSubmittingAnswers}
        />
      )}
    </PageLayout>
  );
};
