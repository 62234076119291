import React from "react";

export const CommonProgressBar = ({ width, label }) => {
  return (
    <div className="common-progress-bar">
      <div className="progress-track">
        <div
          className="progress-highlight"
          style={{
            width: `${width}%`,
          }}
        ></div>
      </div>
      <div className="progress-label-txt">{label}</div>
    </div>
  );
};
