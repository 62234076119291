import React from "react";
import parse from "html-react-parser";
import { useContactUs } from "..";
import { LoadingSpinner } from "shared";

export const ContactUsPage = () => {
  const { settingsInfo } = useContactUs({
    load: true,
    key: "page_Contact_Us",
  });
  const { data, isLoading } = settingsInfo;

  if (isLoading) return <LoadingSpinner />;

  return <p>{data ? parse(data?.content) : ""}</p>;
};
