import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorComponent, LoadingSpinner, PageLayout } from "shared";
import { ProgramDetailsCard, useProgram, HelpFlotingIcon } from "..";
import ErrorImg from "assets/images/error-theory-not-found-graphics.svg";

import bookIconBlue from "assets/images/icons/book-icon-blue.svg";
import theoryIcon from "assets/images/icons/theory-icon.svg";
import suitcaseIconPink from "assets/images/icons/suitcase-icon-pink.svg";
import checklistIconBrown from "assets/images/icons/checklist-icon-brown.svg";
import certificateIconGreen from "assets/images/icons/certificate-icon-green.svg";

export const ProgramDetails = () => {
  const navigate = useNavigate();
  const { name, programDetails } = useProgram({ load: true });
  const { data } = programDetails;

  if (programDetails.isPending || programDetails.isLoading) {
    return <LoadingSpinner />;
  }

  const {
    hasTheory,
    hasLearningMaterials,
    hasAssignment,
    hasAssessment,
    hasCertificates,
    hasHelp,
    batchAssociatesLogoURL,
    programAssociatesLogoUrl,
    learningMaterialsProgress,
    totalAssignmentHour,
    completedAssignmentHour,
    totalAssessmentCount,
    completedAssessmentCount,
    totalCertificatesCount,
    completedCertificatesCount,
    scheduledAssessmentCount,
    courseTypeDetailsDTO,
  } = !!data && data;

  let learningDetails = courseTypeDetailsDTO?.find((e) => e.courseType === 1);
  let OJTDetails = courseTypeDetailsDTO?.find((e) => e.courseType === 2);
  let theoryDetails = courseTypeDetailsDTO?.find((e) => e.courseType === 0);

  const hasAnything =
    hasLearningMaterials ||
    hasAssignment ||
    hasAssessment ||
    hasCertificates ||
    hasTheory;

  const titleEndComponent = [
    <div className="logo-group">
      {programAssociatesLogoUrl && (
        <img src={programAssociatesLogoUrl} alt="logo" />
      )}
      {batchAssociatesLogoURL && (
        <img src={batchAssociatesLogoURL} alt="logo" />
      )}
    </div>,
  ];

  const goToPreviousPage = () => {
    navigate(-1, { replace: true });
  };

  const onProgramDetailsClick = (obj, status) => {
    const path =
      status === 2
        ? "assignment"
        : status === 1
        ? "practicals"
        : "learningmaterials";
    if (obj?.hasCourseGroup) {
      sessionStorage?.setItem("courseList", JSON.stringify(obj));
      navigate(`${path}/coursegroup`);
    } else {
      sessionStorage?.removeItem("courseGroup");
      sessionStorage?.setItem("courseList", JSON.stringify(obj));
      navigate(`${path}/courseslist`);
    }
  };

  return (
    <PageLayout
      title={hasAnything ? name : false}
      titleEndComponent={hasAnything ? titleEndComponent : false}
      breadcrumb={[{ label: name }]}
    >
      <div className="row">
        <div className="col-12">
          {hasAnything ? (
            <>
              <div className="page-grid-items page-grid-items--box">
                {hasTheory && (
                  <ProgramDetailsCard
                    title={theoryDetails?.displayName || "Learning Materials"}
                    theme={"rgba(237, 222, 250, 1)"}
                    icon={theoryIcon}
                    onClick={() => onProgramDetailsClick(theoryDetails, 0)}
                  />
                )}
                {hasLearningMaterials && (
                  <ProgramDetailsCard
                    title={learningDetails?.displayName || "Practicals"}
                    theme={"#eaf2ff"}
                    progress={learningMaterialsProgress}
                    icon={bookIconBlue}
                    onClick={() => onProgramDetailsClick(learningDetails, 1)}
                  />
                )}
                {hasAssignment && (
                  <ProgramDetailsCard
                    title={OJTDetails?.displayName || "Assignment"}
                    theme={"#ffeafa"}
                    totalAssignmentHour={totalAssignmentHour}
                    completedAssignmentHour={completedAssignmentHour}
                    icon={suitcaseIconPink}
                    onClick={() => onProgramDetailsClick(OJTDetails, 2)}
                  />
                )}
                {hasAssessment && (
                  <ProgramDetailsCard
                    title={"Assessment"}
                    theme={"#fdefe0"}
                    totalCount={totalAssessmentCount}
                    completedCount={completedAssessmentCount}
                    icon={checklistIconBrown}
                    isAssessment={true}
                    scheduledAssessmentCount={scheduledAssessmentCount}
                    onClick={() => navigate("assessment")}
                  />
                )}
                {hasCertificates && (
                  <ProgramDetailsCard
                    title={"Certification"}
                    theme={"#e9fbf4"}
                    totalCount={totalCertificatesCount}
                    completedCount={completedCertificatesCount}
                    icon={certificateIconGreen}
                    onClick={() => navigate("certificate")}
                  />
                )}
              </div>
              <div className="page-bottom-logos">{titleEndComponent}</div>
            </>
          ) : (
            <ErrorComponent
              title="No Content Found!"
              message="There is no content added in this program for you at the moment."
              image={ErrorImg}
              btnLabel="Go back"
              onClick={goToPreviousPage}
            />
          )}
        </div>
      </div>
      {hasHelp && <HelpFlotingIcon />}
    </PageLayout>
  );
};
